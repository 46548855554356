import { useCallback, useEffect, useRef, useState } from "react";
import { fetchDataWithCriteria, mace_url } from "../../../adapters";
import { ColumnDirective, ColumnsDirective, DataStateChangeEventArgs, GridComponent, Toolbar } from "@syncfusion/ej2-react-grids";
import { Inject, Page, Sort } from "@syncfusion/ej2-react-grids";
import ReactSlidingPane from "react-sliding-pane";
import Article from "./Article";
import dayjs from "dayjs";
import { TableLoader } from "../../../components/widgets/SkeletonScreens";
import { debounce, isArray } from "lodash";
import { PencilIcon, PlusCircleIcon, TrashIcon, XIcon } from "@heroicons/react/solid";
import { useAppDispatch } from "../../../app/hooks";
import { show } from "../../notification/notificationSlice";
import axios from "axios";
import { useGetAdminConfig } from "../adminHooks";
import ErrorMessage from "../../../components/widgets/ErrorMessage";
import { ClipLoader } from "react-spinners";
import { DocumentIcon } from "@heroicons/react/outline";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

const placeholder = "Search by title";
interface IArticleItemQ {
	data: { results: any[]; totalResults: string };
}

const Articles = () => {
	const dispatch = useAppDispatch();
	const [isProcessing, setIsProcessing] = useState(false);
	const [showSidePanel, setShowSidePanel] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	const [articleId, setArticleId] = useState("");
	const [activePage, setActivePage] = useState(0);
	const [data, setData] = useState<{ result: IArticleItemQ["data"]["results"]; count: string }>();
	const [sortParams, setSortParams] = useState<any[]>([]);
	const [error, setError] = useState<string>("");
	const [searchTerm, setSearchTerm] = useState<string>("");
	const gridControl = useRef<any>("");
	const adminConfig = useGetAdminConfig();
	const articlesCriteria = adminConfig.criteria.articles;

	useEffect(() => {
		fetchData();
		return () => {};
	}, [activePage, sortParams, searchTerm]);

	const fetchData = () => {
		if (articlesCriteria) {
			if (searchTerm) {
				articlesCriteria.push({ field: "title", op: "Contains", values: [searchTerm] });
			}
			if (mace_url) {
				fetchDataWithCriteria(mace_url, "article", {
					pageId: activePage,
					pageSize: adminConfig.pageSize,
					orderBy: sortParams,
					criteria: articlesCriteria,
				}).then((response: IArticleItemQ) => {
					if (gridControl.current) {
						gridControl.current.hideSpinner();
					}
					setData({
						result: response.data.results,
						count: response.data.totalResults,
					});
					setFirstLoad(false);
				});
			}
		} else {
			setFirstLoad(false);
			setError("No articles criteria found for this user");
		}
		setIsProcessing(false);
	};

	const handleStateChange = (state: DataStateChangeEventArgs) => {
		if (state.action) {
			if (state.action.requestType === "sorting") {
				if ("sorted" in state) {
					let sortOrder: any[] = [];
					state.sorted?.forEach((sort) => {
						sortOrder.push({
							field: sort.name,
							direction: sort.direction === "ascending" ? "Asc" : "Desc",
						});
					});
					setSortParams(sortOrder);
				} else {
					setSortParams([]);
				}
			} else if (state.action.requestType === "paging") {
				if ((state.action as any).currentPage - 1 !== activePage) {
					setActivePage((state.action as any).currentPage - 1);
				}
			}
		} else {
			state.skip = 1;
		}
	};

	/*** Column Templates  ***/
	const dateFormatter = (field: string, data: any) => {
		return dayjs(data[field]).format("MM/DD/YYYY");
	};

	const nameTemplate = (props: any) => {
		let field = props.column.field; // this will be the actual column
		let tag = props[field] ?? "No Name";
		if (isArray(tag)) {
			tag = tag.join(", ");
		}
		return (
			<div className="cursor-pointer" onClick={() => handleEditArticle(props.id)}>
				{tag}
			</div>
		);
	};

	const actionsTemplate = (props: any) => {
		return (
			<span className="relative z-0 inline-flex shadow-sm rounded-sm">
				<button title="Edit" type="button" className="relative inline-flex items-center px-2 py-2 rounded-l-sm border border-default bg-primary" onClick={() => handleEditArticle(props.id)}>
					<span className="sr-only">Edit</span>
					<PencilIcon className="w-4 h-4 text-header" aria-hidden="true" />
				</button>
				<button title="Delete" type="button" className="relative inline-flex items-center px-2 py-2 rounded-r-sm border border-default bg-primary" onClick={() => handleDeleteArticle(props.id)}>
					<span className="sr-only">Delete</span>
					<TrashIcon className="w-4 h-4 text-red-500" aria-hidden="true" />
				</button>
			</span>
		);
	};

	const expirationTemplate = (props: any) => {
		let field = props.column.field;
		let tag;
		if (props[field]) {
			tag = props[field];
			if(dayjs().isAfter(dayjs(tag))) {
				tag = <TooltipComponent position="TopLeft" content={`This article expired on ${dayjs(tag).format("MM/DD/YYYY")} and is no<br />longer visible to members`}><span className="text-danger">{dayjs(tag).format("MM/DD/YYYY")}</span></TooltipComponent>;
			} else if(dayjs().add(5, 'day').isAfter(dayjs(tag))) {
				const daysUntilExpiration = dayjs(tag).diff(dayjs(), "day");
				tag = <TooltipComponent position="TopLeft" content={`This article will expire in ${daysUntilExpiration <= 0 ? "less than a day" : `${daysUntilExpiration} ${daysUntilExpiration > 1 ? "days" : "day"}`} and will no longer<br />be visible to members after that date`}><span className="text-warning">{dayjs(tag).format("MM/DD/YYYY")}</span></TooltipComponent>;
			} else {
				tag = <div className="e-control"><span className="text-success">{dayjs(tag).format("MM/DD/YYYY")}</span></div>;
			}
		} else {
			tag = "N/A";
		}
		return tag;
	};

	/*** Actions ***/
	const handleEditArticle = (articleId: string) => {
		setArticleId(articleId);
		setShowSidePanel(true);
	};

	const handleNewArticle = () => {
		setArticleId("");
		setShowSidePanel(true);
	};

	const handleDeleteArticle = (articleId: string) => {
		if (window.confirm("Are you sure you wish to delete this article?")) {
			axios
				.delete(mace_url + "api/app/Article/" + articleId)
				.then(() => {
					dispatch(
						show({
							show: true,
							title: "Success",
							message: "Article deleted succesfully",
							status: "success",
							position: "popover",
							autoHide: 5000,
							confirm: false,
							notificationId: null,
						})
					);
					fetchData();
				})
				.catch((e) => {
					dispatch(
						show({
							show: true,
							title: "Error",
							message: "There was a problem deleting the article. " + e.message,
							status: "error",
							position: "popover",
							autoHide: 5000,
							confirm: false,
							notificationId: null,
						})
					);
					console.error("Error deleting article", e);
				});
		}
	};

	const handleResetSearch = () => {
		const articleSearchInput = document.getElementById("article-search") as HTMLInputElement;
		if (articleSearchInput) {
			articleSearchInput.value = "";
		}
		setSearchTerm("");
	}

	const handleSearch = (searchTerm: string) => {
		debounceSearch.cancel();
		setSearchTerm(searchTerm);
	};
	const debounceSearch = debounce(handleSearch, 500);
	const debouncedHandleSearch = useCallback(debounceSearch, []);

	const handleToolbarClick = (args: any) => {
		if (args.item.id === "RefreshView") {
			fetchData();
		}
	};

	const closeSlider = () => {
		setShowSidePanel(false);
		fetchData();
	};

	return firstLoad ? (
		<TableLoader />
	) : error ? (
		<ErrorMessage message={error} />
	) : (
		<>
			<div className="inline-flex mb-4 pl-2 opacity-90"><DocumentIcon className="h-8 w-8 mr-2" /><h3 className="font-medium text-2xl">Articles</h3></div>
			<div className="crmls-table-main-actions">
				<div className="crmls-actions-left w-full lg:w-3/4">
					<button type="button" className="action-button bg-header w-full sm:w-auto" onClick={handleNewArticle}>
						<PlusCircleIcon className="h-4 w-4 mr-1" /> New Article
					</button>
				</div>
				<div className="crmls-actions-right w-full lg:w-1/4">
					<div className="relative w-full">
						<input
							id="article-search"
							type="text"
							className="w-full input-registered-required"
							placeholder={placeholder}
							onChange={(e) => {
								setIsProcessing(true);
								debouncedHandleSearch(e.target.value);
							}}
						/>
						{!isProcessing && searchTerm && <XIcon className="cursor-pointer w-5 h-5 absolute top-1/2 transform -translate-y-1/2 right-3" onClick={handleResetSearch} />}
						{isProcessing && <div  className="absolute top-1/2 transform -translate-y-1/2 right-3"><ClipLoader aria-label="Loading Results" size={14} /></div>}
					</div>
				</div>
			</div>
			<div className="syncfusion-grid w-full">
				<GridComponent
					ref={gridControl}
					dataStateChange={handleStateChange}
					dataSource={data}
					allowPaging={true}
					allowSorting={true}
					resizeSettings={{ mode: "Auto" }}
					dataBound={() => {
						gridControl.current.autoFitColumns([]);
					}}
					toolbar={[{ text: "Refresh", tooltipText: "Refresh List", prefixIcon: "e-refresh", id: "RefreshView" }]}
					toolbarClick={handleToolbarClick}
				>
					<ColumnsDirective>
						<ColumnDirective field="title" headerText="Title" template={nameTemplate} isPrimaryKey={true} clipMode="EllipsisWithTooltip" />
						<ColumnDirective field="status" headerText="Status" clipMode="EllipsisWithTooltip" />
						<ColumnDirective field="articleCategory.name" headerText="Category" clipMode="EllipsisWithTooltip" />
						<ColumnDirective field="createdOn" headerText="Created On" valueAccessor={dateFormatter} clipMode="EllipsisWithTooltip" />
						<ColumnDirective field="createdByName" headerText="Created By" clipMode="EllipsisWithTooltip" />
						<ColumnDirective field="expiresOn" headerText="Expires On" template={expirationTemplate} clipMode="EllipsisWithTooltip" />
						<ColumnDirective field="actions" headerText="Actions" template={actionsTemplate} textAlign="Right" allowSorting={false} clipMode="EllipsisWithTooltip" />
					</ColumnsDirective>
					<Inject services={[Page, Sort, Toolbar]} />
				</GridComponent>
			</div>

			<ReactSlidingPane
				className="slider-pane"
				overlayClassName="slider-pane-overlay"
				isOpen={showSidePanel}
				width={"85%"}
				title={"Article Detail"}
				onRequestClose={() => {
					setArticleId("");
					setShowSidePanel(false);
				}}
			>
				<Article id={articleId} closeSlider={closeSlider} />
			</ReactSlidingPane>
		</>
	);
};

export default Articles;
